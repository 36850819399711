import React from 'react';
import AboutCard from '../Components/AboutCard';
import about2 from '../images/about2.png';
import '../assets/css/About.css';
import AboutStaff from '../Components/AboutStaff.js';
import staffContent from '../Components/StaffContent';
import AboutLeads from '../Components/AboutLeads';


function About() {
    return (
        <div>
            
            <div style={{ padding:"4% 0px" }} >
                <div className="section-title" >About Us</div>
                <div className="container">
                    <div className="about-top-text" >Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates.Aenean eu purus massa. Maecenas malesuada, libero a eleifend mollis, sapien magna cursus neque, convallis gravida arcu felis id risus. Mauris vestibulum vel erat at porttitor. Aliquam molestie tellus et urna blandit, ornare tristique dolor semper. Nam vel tristique diam. Nulla a elementum metus. Donec imperdiet justo at vulputate volutpat. Nulla ornare nisl nec tincidunt posuere.</div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12" >
                            <img src={about2} alt="website host" className="about-image" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <AboutCard title="Who We Are" description="Nascetur ridiculus mus, Cras at est non quam mattis hendrerit. Fusce purus massa, pretium malesuada eros nec, feugiat iaculis sem. Integer eget tortor justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia." />
                        </div>
                        <div className="col-md-4 col-sm-12" >
                            <AboutCard title="Our Mission" description="Mauris vestibulum vel erat at porttitor. Aliquam molestie tellus et urna blandit, ornare tristique dolor semper. Nam vel tristique diam.Aliquam molestie tellus et urna blandit, ornare tristique dolor semper." />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '4rem' }} >
                <div data-stellar-background-ratio="0.5" className="sm-row-fluid smue-row smue-row-parallax sme-dsbl-margin-left sme-dsbl-margin-right" style={{ backgroundImage: "url(../../assets/images/services.jpg)", backgroundPosition: "50% 71.3359px" }} >
                <div className="sm-span12 smue-clmn  sme-dsbl-margin-left sme-dsbl-margin-right banners">
                    <div className="sm-row-fluid smue-row sme-dsbl-margin-left sme-dsbl-margin-right">
                        <div className="sm-span12 smue-clmn sme-dsbl-margin-left sme-dsbl-margin-right">
                            <div className="smue-text-obj">
                                <h2 style={{textAlign: "center"}}><span style={{color: "#ffffff"}}>It’s The Best SitePad Theme.</span></h2>
                                <p style={{textAlign: "center"}}><span style={{color: "#ffffff"}}>This theme comes with many awesome features and it suits to any kind of businesses. You will love it!</span></p>
                                </div>
                                </div>
                                </div>
                                <div className="sm-row-fluid smue-row sme-dsbl-margin-left sme-dsbl-margin-right">
                                    <div className="sm-span12 smue-clmn sme-dsbl-margin-left sme-dsbl-margin-right">
                                    <div className="smue-button-group-obj smue-text-align-center smue-button-group-horizontal smue-button-group-indent-5 buttons_gr">
                                    <div className="smue-button-inner-obj smue-text-align-left"><a href="#" target="_self" className="smue-btn smue-btn-size-middle smue-btn-icon-indent-small smue-btn-rounded smue-btn-color-silver">Learn More</a></div>
                                    <div className="smue-button-inner-obj smue-text-align-left"><a href="#" target="_self" className="smue-btn smue-btn-size-middle smue-btn-icon-indent-small smue-btn-rounded smue-btn-color-silver">Buy Now!</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-title" >Meet our dedicated Staff</div>
                    <div className="row">
                            {staffContent.map((card, index) => {
                                console.log(card);
                                return (
                                    <div className="col-md-3" key={index}>
                                        <AboutStaff
                                            image={card.image}
                                            name={card.name}
                                            position={card.position}
                                            mobile={card.mobile}
                                            email={card.email}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div className="our-work">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <AboutLeads
                                    icon="fa fa-users"
                                    title="500"
                                    description="Members"
                                />
                            </div>
                            <div className="col">
                                <AboutLeads
                                    icon="fa fa-laptop"
                                    title="1200"
                                    description="Website Hosted"
                                />
                            </div>
                            <div className="col">
                                <AboutLeads
                                    icon="fa fa-trophy"
                                    title="50"
                                    description="Awards Won"
                                />
                            </div>
                            <div className="col">
                                <AboutLeads
                                    icon="fa fa-map-marker"
                                    title="30"
                                    description="Location"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default About;