import React from 'react'

function ServicesCard2(props) {
    return (
        <div style={{ background: "#F9F9F9",borderBottom:' 2px solid', }} >
            <div>
                <img width="100%" src={props.img} alt='img'/>
            </div>
            <div style={{ padding:'20px' }} >
                <div style={{ fontSize:'20px', fontWeight:'500' }} >
                    {props.title}
                </div>
                <div style={{ color: '#707070',fontSize: '12px',lineHeight: '24px' }} >
                    {props.body}
                </div>
            </div>
        </div>
    )
}

export default ServicesCard2
