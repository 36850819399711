import team1 from '../images/team1.webp';
import team2 from '../images/team2.webp';
import team3 from '../images/team3.webp';
import team4 from '../images/team4.webp';


const staffContent =
    [
        {
            image: team1,
            name: "Jason Morley",
            position: "Founder",
            mobile: "0987654321",
            email: "jason@hosting.com"
        },
        {
            image: team2,
            name: "Sara Wade",
            position: "Team Lead",
            mobile: "0987654321",
            email: "sara@hosting.com"
        },
        {
            image: team3,
            name: "Rick Morton",
            position: "UI Designer",
            mobile: "0966219876",
            email: "rick@hosting.com"
        },
        {
            image: team4,
            name: "Grace Mike",
            position: "Web Developer",
            mobile: "0966219876",
            email: "grace@hosting.com"
        }
    ]
export default staffContent;