import React from 'react'
import MessagingNeedHelp from '../Components/MessagingNeedHelp'
import MessagingSection from '../Components/MessagingSection'

function Contact() {
    return (
        <div>
            <div className="row" style={{ padding:'0px 6rem' }} >
                <div className="col-md-8">
                    <MessagingSection/>
                </div>
                <div className="col-md-4">
                    <MessagingNeedHelp/>
                </div>
            </div>
        </div>
    )
}

export default Contact
