import React from 'react';

function BlogContent(props) {
    return (
        <div className="container">
            <h2 style={{fontSize:'3rem', fontWeight:'900', lineHeight:'1.25', marginBottom:'1em', color:'#1a1a1a'}} >{ props.title }</h2>
            <div className="row">
                <div className="col-md-4" style={{ fontSize:'1.1rem', color:'#1D4057' }}>
                
                            <span className={props.icon} ></span> {props.date}
                    
                </div>
                <div className="col-md-8">
                    {props.shortDesc}
                </div>
            </div>
        </div>
    )
}
export default BlogContent;