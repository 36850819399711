import React from 'react'

function MessagingNeedHelpCard(props) {
    return (
        <div>
            <div style={{ background: '#F9F9F9',padding: '20px',margin: '10px 0',borderLeft: '2px solid' }} >
                <div style={{ fontSize: '20px', fontWeight:'500' }} > {props.title} </div>
                <div style={{ color: '#707070',fontSize: '15px',lineHeight: '24px', }} > <a href="#"> {props.description} </a> </div>
            </div>
        </div>
    )
}

export default MessagingNeedHelpCard
