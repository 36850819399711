import React from 'react'

function AboutLeads(props) {
    return (
        <div className="our-work-card" >
            <div style={{ display:'flex', justifyContent:"flex-start" }} >
                <div style={{ fontSize:'2.188rem', color:'#1D4057' }} >
                    <span className={props.icon} ></span>
                </div>
                <div style={{ textAlign:'left',paddingLeft:'1.25rem' }} >
                    <div style={{ fontSize:'2.188rem', fontWeight:'bold' }} > {props.title} </div>
                    <div style={{ fontSize:'0.938rem', lineHeight:'1.5rem', color:'#717171' }} >  {props.description} </div>
                </div>
            </div>
        </div>
    )
}

export default AboutLeads
