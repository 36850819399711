import React from 'react';

function AboutStaff(props) {
    return (
        <div class="card" className="team-card">
            <div className="team-image team-image-zoom image-box" >
                <img src={props.image} class={"card-img-top"} height="250" alt=""/>
            </div>
            <div class="card-body">
                <h5 class="card-title">{props.name}</h5>
                <p class="card-text team-text">
                    {props.position}<br />
                    Mobile: {props.mobile}<br />
                    Email: {props.email}<br />
                </p>
            </div>
        </div>
    )
}
export default AboutStaff;