import React from 'react'
import MessagingNeedHelpCard from './MessagingNeedHelpCard'

function MessagingNeedHelp() {
    return (
        <div>
             <div style={{fontSize: '35px' ,fontWeight: '500',padding: '5px 0 ',marginBottom: '15px'}} >
                Need Help?
            </div>
            <div style={{ color: '#707070',fontSize: '15px',lineHeight: '24px' }} >
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.
            </div>
            <MessagingNeedHelpCard title="Sales & Billing" description="sales@hosting.com" />
            <MessagingNeedHelpCard title="24/7 Support" description="support@hosting.com" />
            <MessagingNeedHelpCard title="Feedback" description="feedback@hosting.com" />
            <MessagingNeedHelpCard title="Bugs Report" description="bugs@hosting.com" />
        </div>
    )
}

export default MessagingNeedHelp
