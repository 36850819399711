import React from 'react';
import BlogContent from '../Components/BlogContent'
function Blog() {
    return (
        <div style={{paddingBottom:'9em'}}>
            <BlogContent
                title="Default Blog Post"
                icon="fa fa-calendar"
                date="May 13, 2021"
                shortDesc="This is a default blog post. Feel free to delete it."
            />
        </div>
    )
}
export default Blog;