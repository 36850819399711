import React from 'react'
import IncludesCard from '../Components/IncludesCard'
import ServicesBanner from '../Components/ServicesBanner'
import ServicesCard2 from '../Components/ServicesCard2'

function Services() {
    return (
        <div>
            <div style={{ padding:'0px 6rem' }} >
                <div style={{ fontWeight:'500', fontSize:"40px",textAlign:'center',paddingTop: '70px' }} > Services We Offer </div>
                <div style={{ marginBottom:'4rem' }} >
                    <div className="row">
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-laptop" title="Website Hosting" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-soundcloud" title="Cloud Hosting" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-share" title="Reseller Hosting" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-database" title="Dedicated Hosting" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-sitemap" title="Virtual Servers" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                        <div className="col-md-4" >
                            <IncludesCard icon="fa fa-globe" title="Domain Registration" description="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis." />
                        </div>
                    </div>
                </div>
            </div>
            <ServicesBanner/>
            <div style={{ padding:'0px 6rem',marginBottom:'5%' }}>
                <div className="row">
                    <div className="col-md-4">
                        <ServicesCard2 img="../assets/images/Services1.jpg" title="Website Designing" body="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis. Vestibulum sit amet tortor velit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua" />
                    </div>
                    <div className="col-md-4">
                        <ServicesCard2 img="../assets/images/Services2.jpg" title="Website Designing" body="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis. Vestibulum sit amet tortor velit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua" />
                    </div>
                    <div className="col-md-4">
                        <ServicesCard2 img="../assets/images/Services3.jpg" title="Website Designing" body="Cras aliquet urna ut sapien tincidunt, quis malesuada elit facilisis. Vestibulum sit amet tortor velit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
