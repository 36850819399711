import React from 'react'

function ServicesBanner() {
    return (
        <div style={{ marginBottom:'7%', }} >
            <div style={{ backgroundColor: 'rgb(42, 50, 62)',color: 'rgb(255, 255, 255)', padding:"28px" }} >
                <div className="row">
                    <div className="col-md-1" style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                        <span className="fa fa-info-circle" style={{ color: '#ffffff',fontSize: '80px' }} ></span>
                    </div>
                    <div className="col-md-9">
                        <div style={{ fontSize:'30px', fontWeight:'500', marginBottom:'12px' }} >
                            Hosting is a totally awesome unique & responsive SitePad template
                        </div>
                        <div style={{ color: '#707070',fontSize: '12px',lineHeight: '24px' }} >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </div>
                    </div>
                    <div className="col-md-2" style={{ display:'flex', justifyContent:'center', alignItems:'center' }} >
                        <button style={{ color: '#4f4f4f',backgroundColor: '#f4f4f4', padding:'10px 36px', fontSize:'15px' }} >Contact Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesBanner
