import React from 'react'

function MessagingSection() {
    return (
        <div>
            <div style={{fontSize: '35px' ,fontWeight: '500',padding: '5px 0 ',marginBottom: '15px'}} >
                Leave a Message
            </div>
            <div style={{ color: '#707070',fontSize: '15px',lineHeight: '24px' }} >
                Quisque volutpat condimentum velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos nam nec ante, urna non. Praesent mauris nec tellus.
            </div>
            <div style={{ padding:"2rem 2rem 0px 0.5rem" }} >
                <input className=" form-control MessageInputs " type="text" placeholder="Name" name="name" />
                <input className=" form-control MessageInputs " type="text" placeholder="Email" name="email" />
                <input className=" form-control MessageInputs " type="text" placeholder="Subject" name="subject" />
                <textarea className=" form-control MessageInputs " placeholder="Message" ></textarea>
                <div>
                    <button style={{ margintop: '5px',marginBottom:'6rem',padding: '12px 20px',backgroundColor: '#1a1a1a',color: '#fff',fontFamily: 'inherit',fontWeight: '700',letterSpacing: '0.046875em',lineHeight: '1',border:'none', marginTop:'14px', fontSize:'14px' }} >SEND MESSAGE</button>
                </div>
            </div>
        </div>
    )
}

export default MessagingSection
