import React from 'react'

function AboutCard(props) {
    return (
        <div className="about-col" >
            <div style={{ display:'flex', justifyContent:"flex-start" }} >
                <div style={{ textAlign:'left',paddingLeft:'1.25rem' }}  className="about-col-text"  >
                    <div style={{ fontSize:'1.25rem', fontWeight:'600', color:'#000' }} > {props.title} </div>
                    <div style={{ fontSize:'0.938' }}>  {props.description} </div>
                </div>
            </div>
        </div>
    )
}

export default AboutCard;
