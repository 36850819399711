import React from 'react'
import '../assets/css/Footer.css'

function Footer() {
    return (
        <div>
            <div style={{ backgroundColor:'#416', padding:'4rem 0rem 60px 0rem', color:'#fff' }} >
                <div className="container">
                    <div className="row" >
                        <div className="col-md-3">
                            <h3>Hosting</h3>
                            <ul className="footer-lists" >
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Web Hosting</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Reseller Hosting</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; VPS Hosting </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Dedicated Servers </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Windows Hosting</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Cloud Hosting</li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Company</h3>
                            <ul className="footer-lists" >
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; About Us</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Press & Media </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; News / Blogs </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Careers </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Awards & Reviews</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Testimonials</li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Our Products</h3>
                            <ul className="footer-lists" >
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Website Builder </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Web Design </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Register Domains </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Traffic Booster </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Search Advertising </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Email Marketing </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Support</h3>
                            <ul className="footer-lists" >
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Product Support </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Reseller Hosting</li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Contact Us </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Knowledge Base  </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Tutorials </li>
                                <li> <span className="fa fa-step-forward" ></span> &nbsp; Magazines</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '0.6% 0% 1% 0%',background: '#800' }} >
                <div className="container">
                    <div style={{ display:'flex', justifyContent:'space-between',alignItems:'center', color:'#fff' }} >
                        <small style={{ fontWeight:'lighter' }} >
                            Theme by Tallen
                        </small>
                        <div style={{ display:'flex', justifyContent:'space-between',alignItems:'center' }} >
                            <div className="FooterSocialMediaIcons" style={{ }} >
                                <span > <img style={{ width:"100%", height:"2rem", backgroundColor:"#fff" }} src="../../assets/images/facebook-svgrepo-com.svg" alt="facebook"/> </span>
                            </div>
                            <div className="FooterSocialMediaIcons" >
                                <span > <img style={{ width:"100%", height:"2rem", backgroundColor:"#fff" }} src="../../assets/images/google-plus-logo-svgrepo-com.svg" alt="facebook"/>  </span>
                            </div>
                            <div className="FooterSocialMediaIcons" >
                                <span > <img style={{ width:"100%", height:"2rem", backgroundColor:"#fff" }} src="../../assets/images/twitter-svgrepo-com.svg" alt="facebook"/> </span>
                            </div>
                            <div className="FooterSocialMediaIcons" >
                                <span> <img style={{ width:"100%", height:"2rem", backgroundColor:"#fff" }} src="../../assets/images/youtube-svgrepo-com.svg" alt="facebook"/> </span>
                            </div>
                            <div className="FooterSocialMediaIcons" >
                                <span > <img style={{ width:"100%", height:"2rem", backgroundColor:"#fff" }} src="../../assets/images/rss-svgrepo-com.svg" alt="facebook"/> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer

