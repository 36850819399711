import React from 'react'

function IncludesCard(props) {
    return (
        <div style={{ padding:'1.25rem',marginTop:"1.875rem", borderLeft:'0.125rem solid #1D4057' }} >
            <div style={{ display:'flex', justifyContent:"flex-start" }} >
                <div style={{ fontSize:'2.188rem', color:'#1D4057' }} >

                    <span className={props.icon} ></span>
                </div>
                <div style={{ textAlign:'left',paddingLeft:'1.25rem' }} >
                    <div style={{ fontSize:'1.25rem' }} > {props.title} </div>
                    <div style={{ fontSize:'0.938' }} >  {props.description} </div>
                </div>
            </div>
        </div>
    )
}

export default IncludesCard
