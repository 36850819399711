import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './Components/Footer';
import Contact from './Views/Contact';
import Services from './Views/Services';
import About from './Views/About';
import Blog from './Views/Blog';
// import  from '';
// import  from '';
// import  from '';

const Header1 = lazy(()=> import('./Components/Header1') ) ;
const Header2 = lazy(()=> import('./Components/Header2') ) ;
const Home = lazy(()=> import('./Views/Home') ) ;

function App() {



  return (
    <Router>
        <Suspense fallback={<div>Loading...</div>} >
          <Header1/>
          <Header2/>
        </Suspense>
        
        <Switch>

         <Route exact path='/' render={ ()=> <Suspense fallback={<div>Loading...</div>} > <Home/> </Suspense> }/>

          <Route exact path='/Contact-Us' render={()=> <Suspense fallback={<div>Loading...</div>} > <Contact/> </Suspense>} />

          <Route exact path='/Services' render={()=> <Suspense fallback={<div>Loading...</div>} > <Services/> </Suspense>} />
          <Route exact path='/About' render={()=> <Suspense fallback={<div>Loading...</div>} > <About/> </Suspense>} />
          <Route exact path='/Blog' render={()=> <Suspense fallback={<div>Loading...</div>} > <Blog/> </Suspense>} />

         {/* <Route exact path='/signIn' render={()=> <SignInSingUpLayout > <SignIn/> </SignInSingUpLayout> } />

         <Route exact path='/categories' component={CategoriesViewPage}/>

         <Route exact path='/tool' component={ToolViewPage}/> */}

        </Switch>
        {/* <Footer /> */}
        <Suspense fallback={<div>Loading...</div>} >
          <Footer/>
        </Suspense>
      </Router>
  );
}

export default App;
